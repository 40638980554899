
const INFO = {
	main: {
		title: "Portfolio by Kaushik",
		name: "Kaushik Pattipati",
		email: "patKaush@gmail.com",
	},

	socials: {
		github: "https://github.com/patkaush",
		linkedin: "https://www.linkedin.com/in/kaushik-pattipati/",
		leetcode : "https://leetcode.com/patkaush/"
	},

	homepage: {
		title: "",
		description:["As a Full-stack Developer with extensive expertise in Java Frameworks, I specialize in building scalable, secure, and reliable web applications. My experience encompasses a wide range of frameworks and technologies, enabling me to tackle complex problems and continuously learn new skills.I am passionate about writing high-quality code that adheres to best practices and industry standards.",
		"Currently, I am pursuing a Master's degree in Computer Science and actively increasing my knowledge in development to stay at the forefront of technological advancements.Driven by a desire for excellence, I am always seeking new challenges and opportunities to further my growth as a developer."],
		keywords: ["building scalable","secure","reliable web applications","high-quality code","master's degree in computer science"]
		
	},

};

export default INFO;
