const	user_details =  {
		name: "Kaushik Pattipati",
		email: "patKaush@gmail.com",
		description:["As a Full-stack Developer with extensive expertise in Java Frameworks, I specialize in building scalable, secure, and reliable web applications. My experience encompasses a wide range of frameworks and technologies, enabling me to tackle complex problems and continuously learn new skills.I am passionate about writing high-quality code that adheres to best practices and industry standards.",
		"Currently, I am pursuing a Master's degree in Computer Science and actively increasing my knowledge in development to stay at the forefront of technological advancements.Driven by a desire for excellence, I am always seeking new challenges and opportunities to further my growth as a developer."],
		keywords: ["building scalable","secure","reliable web applications","high-quality code","master's degree in computer science"]
	};

const user_socials =  {
		github: "https://github.com/patkaush",
		linkedin: "https://www.linkedin.com/in/kaushik-pattipati/",
		leetcode : "https://leetcode.com/patkaush/",
		hackerrank : "https://www.hackerrank.com/profile/kaushikpat",
	};
const qualities = {
	Restorative : "People with strong Restorative talents love to solve problems. While some are discouraged when they encounter yet \
	another breakdown, this energizes those with strong Restorative talents. They enjoy the challenge of analyzing \
	symptoms, identifying what is wrong and finding the solution. They like bringing things back to life by fixing them or \
	rekindling their vitality. In short, they bring courage and creativity to problematic situations." ,
	Arranger : "Arrangers are conductors. When faced with a complex situation involving many factors, people with strong Arranger \
	talents enjoy managing all of the variables, aligning and realigning them until they are sure they have arranged them in the \
	most productive configuration possible. They are shining examples of effective flexibility, whether they are changing \
	travel schedules at the last minute because they found a better fare or are mulling over just the right combination of \
	people and resources to accomplish a new project. From the mundane to the complex, they are always looking for the \
	perfect configuration.",
	Deliberative : "People with strong Deliberative talents are careful and vigilant. Everything may seem in order, but beneath the surface, \
	they sense many risks. Rather than avoiding these hazards, they draw them out into the open so they can identify, assess \
	and ultimately reduce each risk. Thus, those with strong Deliberative talents bring a thorough and conscientious  \
	approach to making decisions. They take care to consider options, thinking through the pros and cons of each \
	alternative. To them, making the correct choice is more important than the time it takes to do so. They see life as \
	something of a minefield. Others may run through it recklessly if they so choose, but those with Deliberative talents take \
	a different approach. They identify the dangers, weigh these risks’ relative effect and then place their feet deliberately. \
	They walk with care.",
	Analytical : "People with strong Analytical talents challenge others to prove it. They take a critical approach to what others might \
	quickly accept as truth. They search for the reasons why things are the way they are. They want to understand how \
	certain patterns affect one another: how they combine, the outcome and if the result fits with the theory or the situation. \
	Others see them as logical and rigorous. Some might feel that they are negative or unnecessarily critical when they are \
	simply trying to understand something. They bring an objective and dispassionate examination to things enabling them \
	to find the causes and effects, and then developing clear thoughts based on facts.",
	Individualization : "People with strong Individualization talents understand and are intrigued by others’ unique qualities. Impatient with \
	generalizations, they focus on the differences among individuals. They instinctively observe each person’s style and \
	motivation, how each thinks, and how each builds relationships. They keenly observe other people’s strengths and draw \
	out the best in each person. Their Individualization talents help them build productive teams. While some search for the \
	perfect team “structure” or “process,” these employees know instinctively that the secret to great teams is casting by \
	individual strengths — so that everyone can do a lot of what they already do well." 
}	
let about_profile = {user_details : user_details ,
				user_socials : user_socials,
				qualities : qualities}
export default about_profile;
